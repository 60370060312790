import blue from "@material-ui/core/colors/blue"
import pink from "@material-ui/core/colors/pink"
import { create } from "jss"
import { jssPreset as preset, createTheme } from "@material-ui/core/styles"
import { css } from "styled-components"

const sizes = {
  xl: 1920,
  lg: 1280,
  md: 960,
  sm: 600,
  xs: 360
}

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `

  return acc
}, {})

const theme = {
  colors: {},
  dimensions: {},
  fonts: {},
  media
}
export default theme

// https://material-ui-next.com/customization/themes/
export const muiTheme = createTheme({
  palette: {
    primary: blue,
    secondary: pink
  }
})

export const jss = create(preset())
// We define a custom insertion point JSS will look for injecting the styles in the DOM.
jss.options.insertionPoint = document.getElementById("insertion-point-jss")
